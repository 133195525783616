
.footer {
    height: 150px;
    background-color: black;
    display: flex;
    overflow-y: hidden;
}
.icons {
    height: 40px;
    margin-right: 30px;
}

.icons:first-child {
    margin-left: 10%;
}

.copy {
    color: white;
    margin-top: 60px;
    margin-left: 60px;
    font-size: 16px;
    font-family: Avenir;
    font-style: heavy;
    width: 75%;
}

@media (max-width: 600px){
    .icons {
        margin-top: 40px;
    }
    .copy {
        margin-left: 3%;
        font-size: 14px;
    }
}

