.header {
    width: 100%; 
    height: 900px;
    background-size: cover;
    background-image: url('../img/code-un.jpg');
    backdrop-filter: blur(3px);
    -webkit-filter: blur(3px);
}
.header-wrapper {
  background-color: black;
  height: 900px;
}
.top {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  backdrop-filter: blur(0px);
  -webkit-filter: blur(0px);
}

.pro-line {
  margin-left: -5%;
}

.about-line {
  margin-left: 5%;
}

  a {
    margin-top: 50px;
    margin-bottom: 150px;
    color: white;
    font-size: 30px;
    width: 80px;
    text-decoration: none;
    padding-bottom: 0;
    font-family: Avenir;
    font-weight: 800;
  }


  a:hover {
    transform: scale(1.1);
  }

  .web {
    font-size: 50px;
    color: white;
    text-align: left;
    margin-left: 7%;
    font-family: Unna;
    font-style: normal;
    font-weight: normal;
    backdrop-filter: blur(0px);
    -webkit-filter: blur(0px);
  }

  .name {
    font-size: 90px;
    text-align: left;
    margin-left: 7%;
    color: white;
    font-weight: bold;
    font-family: Unna;
    backdrop-filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  .heading {
    margin-top: -850px;
  }
  .para {
    backdrop-filter: blur(0px);
    -webkit-filter: blur(0px);
    text-align: left;
    margin-left: 7%;
    color: white;
    font-size: 30px;
    line-height: 41px;
    width: 84%;
  }
  
  @media (max-width: 800px){
      .header {
          width: 100%;
          overflow-x: hidden;
      }
      .name{
        font-size: 40px;
        margin: 0 auto;
        text-align: center;
      }
      .web {
        font-size: 32px;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
      }
      .top {
        flex-direction: column;
        width: auto;
      }
      a {
          font-size: 36px;
          text-align: center;
          width: auto;
          margin-top: 10px;
          margin-bottom: 25px;

      }
      a:hover {
        transform: scale(1);
      }
    
      a:last-child {
        margin-bottom: 70px;
      }
      .para {
        margin-top: 40px;
        width: auto;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 20px;
      }
      .firsta {
        font-size: 36px;
      }
      .pro-line {
        margin-left: 0;
      }
      .about-line {
        margin-left: 0;
      }
  }