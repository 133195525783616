.skills {
    padding-top: 200px;
    width: 100%;
}

li {
    color: white;
    font-size: 26px;
    margin: 0 auto;
    line-height: 2;
    width: 30%;
}

.box {
    padding-top: 50px;
    background-color: #23443C;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-bottom: 70px;
}
h1 {
    margin-bottom: 20px;
}

.row {
    display: flex;
    margin-left: 12%;
    margin-right: 12%;
}


@media (max-width: 600px){ 
    .skills {
        overflow-x: hidden;
        padding-top: 160px;
    }
    li {
        margin: 0 auto;
    }
    .row {
        flex-direction: column;
        margin: 0 auto;
    }
    .box {
        width: 100%;
        height: auto;
        background-color: #23443C;
    }
}