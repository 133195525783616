.container {
  width: 100%;
  margin: 0 auto;
  background-color: black;
}


@media (max-width: 600px){
  .container {
    overflow-x: hidden;
  }
  
}
