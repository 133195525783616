.contact {
    padding-top: 200px;
    background-color: black;
    height: 100%;
    margin: 0 auto;
}

.phone {
    font-size: 30px;
    margin: 0 auto;
    color: white;
    margin-bottom: 10px;
}

.email {
    font-size: 30px;
    margin: 0 auto;
    color: white;
    margin-bottom: 10px;
}


.contact-box {
    margin: 0 auto;
}


.twitter {
   font-size: 30px;
}

.linkedin {
    font-size: 30px;
}

.github {
    font-size: 30px;
    margin: 0 auto;
}

.contact-title {
    margin: 20px auto;
}

.social {
    font-size: 30px;
    text-decoration: underline;
}
.social:hover {
    color:#15596F;
    transform: scale(1.5);
}

.contact-box {
    text-align: center;
    padding-bottom: 180px;
    padding-top: 40px;
}

.contact-title {
    font-size: 50px;
    text-decoration: underline;
}


@media (max-height: 600px){
    .contact {
        height: 200vh;
    }
}

@media (max-width: 600px){ 
    .contact {
        overflow-x: hidden;
        overflow-y: hidden;
        padding-top: 130px;
    }
    .contact-title {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .phone {
        font-size: 18px;
        margin: 0;
        margin-bottom: 30px;
        text-align: left;
        margin-left: 10%;
    }
    .email {
        font-size: 18px;
        margin: 0;
        margin-bottom: 30px;
        text-align: left;
        margin-left: 10%;
    }
    .box-contact {
        width: 96%;
        margin-left: 2%;
        height: auto;
        padding-right: 0;
        padding-bottom: 0;
    }
    .contact-box {
        width: 82%;
        height: auto;
        padding-right: 0;
        padding-bottom: 30px;
        margin-left: 2%;
    }
    .linkedin {
        font-size: 16px;
        margin-bottom: 30px;
        text-align: left;
        margin-left: 10%;
    }
    .github {
        font-size: 16px;
        margin-bottom: 30px;
        text-align: left;
        margin-left: 10%;
    }
    .contact-title {
        width: auto;
    }
    .social {
        font-size: 16px;
    }
}