.projects {
    width: 100%;
    padding-bottom: 20px;
    background-color: black;
    border-radius: 10px;
    padding-top: 50px;
}

h1 {
    color: white;
    text-align: center;
    height: 40px;
}


p {
    color: white;
    font-family: Avenir;
    font-size: 24px;
}
.words {
    background-color: black;
    height: 100%;
    padding-left: 30px;
    padding-right: 10px;
    margin-top: 20px;
    width: 60%;
}

.dorothy {
    display: flex;
    justify-content: space-around;
}

.cloud-div {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
    height: 100%;
}
.cloud {
    width: 46%;
    height: 350px;
    margin-left: 40px;
    margin-top: 200px;
}
.bunker-words {
    margin-top: 0px;
    width: 61%;
    padding-bottom: 50px;
}

.db-img {
    width: 375px;
    height: 700px;
    margin-top: 0px;
    margin-top: 50px;
}
.db-heading:hover {
    color: #3366CC;
}

.cloud-heading:hover {
    color: #478529;
}

.didact-heading:hover {
    color: #EBE8E1;
}

.didact-pic {
    max-width: 35%;
    width: auto;
    height: auto;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 250px;
    margin-right: 20px;
}

.didact-div{
    display: flex;
    margin-bottom: 50px;
    margin-top: 20px;
}

.didact-words {
    margin-left: 20px;
    margin-top: 80px;
} 

.tweet-words {
    margin-top: 60px;
    margin-left: 20px;
    padding-right: 0px;
    width: 45%;
}


.tweet {
    display: flex;
    margin-bottom: -60px;
}

.tweet-pic {
    width: 46%;
    height: 450px;
    margin-left: 20px;
    margin-top: 180px;
}

.TB-heading:hover {
    color: #00ACEE;
}

.project-heading{
    font-size: 50px;
    text-decoration: underline;
    text-align: left;
} 
.role {
    color: white;
    font-size: 30px;
} 

@media (max-width: 800px){
    .anchorT {
        font-size: 30px;
    }
}

@media (max-width: 800px){ 
    .projects {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-top: 80px;
    }
    .dorothy {
        flex-direction: column;
    }
    .project-heading {
        font-size: 30px;
    }
    .bunker-words {
        padding-bottom: 0px;
    }
    .db-img {
        height: auto;
        width: 96%;
        margin-left: 2%;
    }
    p {
        width: 96%;
    }
    .words {
        margin-left: 2%;
        margin-right: 2%;
        order: 2;
        width: 86%;
        font-size: 16px;
    }
    .role {
        font-size: 28px;
    }
    .cloud-div {
        flex-direction: column;
        margin-top: 0;
    }
    .cloud {
        width: 96%;
        height: auto;
        margin-left: 2%;
        order: 1;
    }
    .cloud {
        margin-left: 2%;
        margin-top: 80px;
    }
    .bunker-words {
        margin-top: 40px;
    }

    .didact-pic {
        max-width: 100%;
        width: 96%;
        height: auto;
        margin-left: 2%;
        margin-right: 2%;
    }
    .didact-div {
        flex-direction: column;
        margin-top: 0;
    }
    .didact-words {
        margin-top: 40px;
    }
    .didact-pic {
        margin-top: 80px;
    }

    .tweet-words {
        margin-top: 40px;
        margin-left: 2%;
        width: 86%;
        padding-right: 0;
    }
    .tweet-pic {
        width: 96%;
        height: auto;
        margin-left: 2%;
        margin-top: 80px;
    }
    .tweet {
        flex-direction: column;
        margin-bottom: 0;
    }
    a {
        font-size: 16px;
    }
    .git-tweet {
        text-decoration: underline;
        font-size: 26px;
    }
}