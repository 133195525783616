.about {
    padding-top: 100px;
    height: 100%;
    background-color: black;
    color: white;
  }
  h1{
      margin-bottom: 15px;
  }
 
 .aboutme-img {
     width: 380px;
     height: auto;
     margin-left: 15px;
     margin-right: 15px;
     border-radius: 20px;
 }
 .aboutme-img-2 {
    width: 50%;
    height: auto;
    max-height: 500px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
 .abt-flex{
     display: flex;
     flex-direction: row;
     margin-top: 70px;
     margin-bottom: 20px;
 }
 .words{
     margin-right: 15px;
     margin-left: 15px;
 }

@media (max-width: 800px){
    .abt-flex{
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .mobile{
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
    }
}